import React, { useState }from 'react';
import { Helmet } from "react-helmet";
import promotionsService from 'services/promotionsService';
import Banner from 'components/Banner/Banner';
import attractionLogos from 'utils/attractionsLogos';
import AttractionModalBlock from 'components/AttractionModalBlock/AttractionModalBlock';
import Link from 'components/Link/Link';
import useEffectOnce from 'hooks/useEffectOnce';
import ModuleBanner from 'assets/images/campaign-2023/banner/banner-headline.png';
import ModuleBanner_2x from 'assets/images/campaign-2023/banner/banner-headline@2x.png';
import ModuleBannerMob from 'assets/images/campaign-2023/banner/banner-headline-mob.png';
import ModuleBannerMob_2x from 'assets/images/campaign-2023/banner/banner-headline-mob@2x.png';

import './AttractionSelection.scss';

export default (props) => {
  const [attractionModal, setAttractionModal] = useState(null);
  const [attractionList, setAttractionList] = useState({});
  const [hasRequestedData, setHasRequestedData] = useState(false);
  let currentYear = new Date().getFullYear();

  useEffectOnce(()=>{
    async function getAttractionsList() {
      await promotionsService.getAttractionsList(props.promotion)
      .then((data) => { 
        setAttractionList(data);
        setHasRequestedData(true); 
      })
      .catch(err => console.log(err)) ;
    }
    getAttractionsList();
  });

  const handleAttractionModalClick = (index) => {
    setAttractionModal(null);
    setTimeout(() => {   setAttractionModal(index); }, 200);
  }

  const handleAttractionModalCloseClick = (index) => {
    setAttractionModal(null);
    //setTimeout(() => {   setAttractionModal(index); }, 200);
  }


  return <>  
    <Helmet>
      <meta charSet="utf-8" />
      <title>Choose your attraction | Cadbury</title>
    </Helmet>
    <section className="layout layout-general layout-wrapper layout-wrapper--column ">

      <Banner 
        image={ModuleBanner}
        image2x={ModuleBanner_2x}
        imageMob={ModuleBannerMob}
        imageMob2x={ModuleBannerMob_2x}
        alt={"Theme park ballons"}
      />
      
      <div className="layout-container layout-container--medium layout-container--center">
        <h1 className="type-h2 type-h2--gold">Choose your attraction</h1>
        <p className="attraction-list__text">
            Pick your favourite day out from below, choose your preferred date and time and enter your unique code(s) to claim your relevant discount*. 
        </p>
                
        {
          (hasRequestedData && attractionList ) ? (
            <div className='attraction-list'>
              {
                
                attractionList.map((attr, index) => {
                  let logos = attractionLogos.filter(entry => entry.id === attr.id);
                  return(<>
                  {
                  attr.locations[0] !== undefined ? (
                    <div className='attraction-list__item'  key={"attraction_"+(index+1)}>
                        <AttractionModalBlock  
                          id={"attraction-block-"+(index+1)}
                          attr_id={attr.id} 
                          title={attr.title} 
                          image={(logos[0] ? logos[0].image : null)} 
                          image2x={(logos[0] ? logos[0].image2x : null)} 
                          promoCode={props.promoCode}
                          locations={attr.locations}
                          open={`${attractionModal}` === "attraction-block-"+(index+1)}
                          onClick={handleAttractionModalClick.bind(this, "attraction-block-"+(index+1))}
                          onClickClose={handleAttractionModalCloseClick.bind(this, "attraction-block-"+(index+1))}
                          />
                    </div>
                  ) : (
                    <div className='attraction-list__item' key={"attraction_"+(index+1)}>
                      <Link 
                        analytics={{evnt: "AttractionSelection", category: props.promoCode, action: "Open"+attr.title.replace(/\s/g, '')}}
                        href={attr.link + props.promoCode}
                        target='_blank'
                        className='attraction-block'
                        title={attr.title} >
                          {
                            logos.length > 0 &&
                            <img src={logos[0].image} srcSet={logos[0].image2x + " 2x, " + logos[0].image + " 1x"} alt={attr.title + " Logo"} />
                          }
                          
                          <span>{attr.title}</span>
                        </Link>
                    </div>
                  )
                }
                  </>)
                })
              }
            </div>
          ) : (  
            <div>Loading...</div>
          )
        }
        
        

        <div className='type-disclaimer'>
          <p >          
            LEGO, the LEGO logo, the Brick and Knob configurations, the Minifigure, DUPLO and LEGOLAND are trademarks of the LEGO Group. &#169; {currentYear} The LEGO Group. &#169; The London Eye {currentYear} all rights reserved. &#169; {currentYear} DreamWorks Animation L.L.C.
          </p>
          <p>* 2024 packs: 10-50% off for up to 4 Adult and Child online priced entry tickets. See full <a href='/terms-and-conditions' target='_blank'>terms and conditions</a> for more details.</p>
          <p>** 2023 packs: *Up to 50% off for up to 4 "On The Day" full priced Adult and Children entry tickets. See full <a href='/terms-and-conditions' target='_blank'>terms and conditions</a> for more details.</p>
        </div>
        
      </div>

    </section>
  </>;
}