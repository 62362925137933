import React from 'react';
import classList from 'react-classlist-helper';
import Link from 'components/Link/Link';

import './SummaryTsandCs.scss';

const SummaryTsandCs = (props) => {
  let currentYear = new Date().getFullYear();
  const classMap = {
    "layout-wrapper": true,
    [props.className]: !!props.className,
  };

  const layoutContainerClass = {
    "layout-container layout-container--center": true,
    [props.layoutContainerClass]: !!props.layoutContainerClass,
  };

  return (
    <section className={classList(classMap)}>
      <div className={classList(layoutContainerClass)}>
        <div className='sumtsandcs'>

          <div className='sumtsandcs__title'>Summary Terms and Conditions:</div>
          <div className='sumtsandcs__disclaimer'>
            <p>These terms and conditions may be updated from time to time. Visit this page before booking to ensure you have the latest terms and conditions.  Ticket availability limitations (or even attraction closures) apply; safety measures or operational changes may be required; for more details visit the website of the attraction you wish to visit for the latest information.</p>
            <p><strong>Please note:</strong> After six years in operation, The Bear Grylls Adventure will close at the beginning of December. This has been a very difficult decision and one that was made only after careful consideration. Our priority now is looking after our team members and animals throughout this transition.  Over the coming weeks, we will continue to provide world-class animal care as we prepare to transfer our animals to suitable new homes, including to our nearby aquarium the National SEA LIFE Centre Birmingham and other UK SEA LIFE sites. Our team of welfare specialists at SEA LIFE will oversee the transition process to ensure it runs as smoothly as possible. We are proud of the positive impact The Bear Grylls Adventure has had on thousands of children and families that have visited over the last six years, showing guests first-hand the power of immersive activity in escaping the ordinary, and how adventure is for everyone. We want to thank our amazing team and guests who have made this time so memorable.</p>          
          </div>

          <div className='sumtsandcs__section'>
            <div className='sumtsandcs__title'>
              BIG TICKET SAVINGS - 2024 packs
              <span>Up to 50% off online priced tickets</span>
            </div>

            <p>
              UK + ROI 18+. This offer gives 10-50% off for up to 4 Adult and Child online priced entry tickets, from 15th April 2024 until 30th June 2025 at selected Merlin Entertainments attractions. Discount percentage depends on attraction and day type chosen (super off peak, off peak, peak, super peak), from a minimum of 10% in super peak, up to a maximum of 50% on select super off peak dates.<strong> Pre-booking online is essential.</strong> Promotional tickets are subject to availability and must be purchased no later than 23:00 the night before your chosen date of visit. Promotional tickets for Cadbury’s customers are limited in numbers for each day the attraction is open, subject to a minimum number allocated to every day each attraction opens, therefore customers are advised to plan ahead and book early. <strong>For full <Link to="/terms-and-conditions" route title="Terms and Conditions" >T&Cs</Link>, information on day type dates and to book, visit <a href="https://fun.cadbury.co.uk" target="_blank" rel="noopener noreferrer">www.fun.cadbury.co.uk</a> and enter your unique barcode/batchcode from the reverse of this pack. </strong> Cannot be used in conjunction with any other promotion. Max 1 entry (covering up to 4 tickets) per promotional pack. Age/height restrictions apply. Promoter: Mondelez Europe Services GmbH - UK Branch, Sanderson Road, Uxbridge, UB8 1DH.
            </p>
            <p>
              LEGO, the LEGO logo, the Brick and Knob configurations, the Minifigure, DUPLO and LEGOLAND are trademarks of the LEGO Group.  &#169; {currentYear} The LEGO Group. &#169; The London Eye {currentYear} all rights reserved. &#169; {currentYear} DreamWorks Animation L.L.C.
            </p>
          </div>

          <div className='sumtsandcs__section'>
            <div className='sumtsandcs__title'>
              BIG TICKET SAVINGS - 2023 Cadbury Chocolate & Biscuits Multipack
              <span>UP TO 50% OFF a family day out</span>
            </div>

            <p>
              UK + ROI 18+. UP TO 50% OFF for up to 4 "On The Day" full priced <strong>Adult and Children entry tickets</strong> from 01.05.23 until 31.07.24 at selected Merlin Entertainments attractions. Discount depends on attraction and period of time chosen (super off peak, off peak, peak, super peak), from a minimum of 25% in super peak, up to a maximum (for certain attractions) of 55% in super off peak. <strong>Merlin Entertainments may limit discount ticket allocations, subject to a minimum number allocated to every day each attraction opens;</strong> early booking is recommended. Pre-booking online is essential; for full <Link to="/terms-and-conditions" route title="Terms and Conditions" >T&Cs</Link> and to book, visit <a href="https://fun.cadbury.co.uk" target="_blank" rel="noopener noreferrer">www.fun.cadbury.co.uk</a> and enter your unique barcode/batchcode from the reverse of this pack. Cannot be used in conjunction with any other promotion. Max 1 entry per promotional pack. Age/height restrictions apply. Promoter: Mondelez Europe Services GmbH - UK Branch, Sanderson Road, Uxbridge, UB8 1DH.
            </p>
            <p>
              LEGO, the LEGO logo, the Brick and Knob configurations, the Minifigure, DUPLO and LEGOLAND are trademarks of the LEGO Group. &#169; 2023 The LEGO Group. &#169; The London Eye 2023 all rights reserved. &#169; 2023 DreamWorks Animation L.L.C.
            </p>
            <p>
              *"On The Day" prices are referred to on some Attractions' websites as "on the door", or "gate" prices.
            </p>
          </div>

          <div className='sumtsandcs__section'>
            <div className='sumtsandcs__title'>
              BIG TICKET SAVINGS - 2023 Selection Boxes and Seasonal Biscuits.
              <span>UP TO 50% OFF a family day out</span>
            </div>

            <p>
              UK + ROI 18+. UP TO 50% OFF for up to 4 "On The Day" full priced <strong>Adult and Children entry tickets</strong> until 31.12.24 at selected Merlin Entertainments attractions. Discount depends on attraction and period of time chosen (super off peak, off peak, peak, super peak), from a minimum of 25% in super peak, up to a maximum (for certain attractions) of 55% in super off peak. <strong>Merlin Entertainments may limit discount ticket allocations, subject to a minimum number allocated to every day each attraction opens;</strong> early booking is recommended. Pre-booking online is essential; for full <Link to="/terms-and-conditions" route title="Terms and Conditions" >T&Cs</Link> and to book, visit <a href="https://fun.cadbury.co.uk" target="_blank" rel="noopener noreferrer">www.fun.cadbury.co.uk</a> and enter your unique barcode/batchcode from the reverse of this pack. Cannot be used in conjunction with any other promotion. Max 1 entry per promotional pack. Age/height restrictions apply. Promoter: Mondelez Europe Services GmbH - UK Branch, Sanderson Road, Uxbridge, UB8 1DH.
            </p>
          </div>
        </div>


      </div>


    </section>
  )
}

export default SummaryTsandCs;
